import * as React from "react"
import { FC, useContext } from "react"
import { LoginContext } from "@social-supermarket/social-supermarket-components"
import Layout from "../../social-supermarket/components/Layout"
import SEO from "../../social-supermarket/components/Seo"
import CheckoutPage from "../../social-supermarket/components/checkout/CheckoutPage"
import { OrderContextType } from "../../social-supermarket/model/OrderContextType"
import { SuppliesOrderContext } from "../../social-supermarket/context/SuppliesOrderProvider"

interface Props {}

const SuppliesCheckout: FC<Props> = () => {
  const loginContext = useContext(LoginContext)
  const context = useContext<OrderContextType>(SuppliesOrderContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Supplies Checkout"} description={""} />
      <CheckoutPage context={context} />
    </Layout>
  ) : (
    <Layout />
  )
}

export default SuppliesCheckout
